import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <section className='pb-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-4 mb-lg-0">
                        <div className="d-flex flex-wrap h-100">
                            <div className='w-100'>
                                <img src="./../images/logo.svg" className='mb-3' alt="JKT" style={{height: 20, width: 'auto'}} />
                                <p className='normal font__size--14 text__14-1024 color__gray-1'>We are a team of branding experts, marketing strategists, digital traders, designers, and growth programmers.</p>
                            </div>
                            <div className="align-self-end d-none d-lg-block">
                                <p className='mb-0 normal font__size--14 text__14-1024'>© Copyright {new Date().getFullYear()}, All Rights Reserved by JKT Fin Capital</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0 col-xl-3 offset-xl-1">
                        <div className="row">
                            <div className="col-6">
                                <h5 className='semi-bold font__size--16 text__16-1024 mb-3'>Help</h5>
                                <ul className="list__menu-footer font__size--14 text__14-1024 normal">
                                    <li><NavLink to="/privacy" className='color__black opacity__7 hover'>Privacy Policy</NavLink></li>
                                    <li><NavLink to="/contact" className='color__black opacity__7 hover'>Contact us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none">
                    <p className='normal mb-0 font__size--14 text__14-1024 text-center'>© Copyright 2022, All Rights Reserved by Nagency</p>
                </div>
            </div>
        </section>
    )
}

export default Footer