import React from 'react'
import { NavLink } from 'react-router-dom'

const ContactUs = () => {
    return (
        <section>
            <div className="container">
                <div className="wrapper__contact-us position-relative">
                    <img src="./../images/Mask group (3).png" className='images__bg-contact' alt="" />
                    <img src="./../images/Rectangle 22144.png" className='images__user-contact d-none d-lg-block' alt="" />
                    <div className="row justify-content-end position-relative z-2">
                        <div className="col-lg-7 col-xl-6 text-center">
                            <p className='medium font__size--18 text__18-1024 color__white'>Contact us</p>
                            <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm color__white mb-0'>We help you to grow your business faster & easier.</h3>
                            <p className='normal font__size--18 text__18-1024 color__white my-4'>Get in touch today and let's start making waves together!</p>
                            <NavLink to="/contact" className='semi-bold font__size--14 text__14-1024 btn btn__pink color__white shadow'>Contact Us</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs