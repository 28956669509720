import React, { Fragment } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false)

  return (
    <Fragment>
      <div className={"wrapper__side-nav-mobile d-flex d-lg-none " + (toogleMenu ? "active" : "")}>
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                class="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
              >
                <img src="./../images/logo.svg" alt="JKT" style={{height: 20, width: "auto"}} />
              </NavLink>
              <img src="./../images/clsoe2.png" onClick={() => setToogleMenu(!toogleMenu)} className="pointer" alt="" />
            </div>
            <div className="menu">
              <NavLink to="/" className="medium font__size--14 text__14-1024 color__black">Home</NavLink>
              <hr />
              <NavLink to="/contact" className="medium font__size--14 text__14-1024 color__black">Contact</NavLink>

            </div>
          </div>
        </div>
      </div >
      <div className="bg__wrap-menu d-lg-none" onClick={() => setToogleMenu(!toogleMenu)}></div>
      <nav
        className="navbar navbar-expand-lg bg__black-2 wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative align-items-center">
          <NavLink
            to="/"
            exact
            class="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
          >
            <img src="./../images/logo.svg" alt="JKT" style={{height: 30, width: 'auto'}} />
          </NavLink>
          <div
            onClick={() => setToogleMenu(!toogleMenu)}
            class="nav__button d-lg-none position-relative ml-auto flex-shrink-0"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <NavLink to="/contact" className="d-none d-lg-block semi-bold font__size--14 text__14-1024 btn btn__pink color__white shadow ml-auto text-capitalize">Contact Us</NavLink>
        </div>
      </nav>
    </Fragment >
  );
};

export default Navbar;
