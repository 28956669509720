import React from 'react'
import { NavLink } from 'react-router-dom'

const Service = (props) => {
    const services = [
        {
            img: "./../images/Chart.png",
            title: "Consultant Plan",
            desc: "Our team provides personalized advice and actionable solutions to help you achieve your goals efficiently.",
            link: "#!"
        },
        {
            img: "./../images/Graph.png",
            title: "Design Thingking",
            desc: "Design Thinking is a creative problem-solving approach that prioritizes user needs and experiences.",
            link: "#!"
        },
        {
            img: "./../images/Filter 2.png",
            title: "SEO Marketing",
            desc: "We optimizes your website to rank higher in search engine results, driving organic traffic and increasing visibility for your business.",
            link: "#!"
        },
        {
            img: "./../images/Work.png",
            title: "Business Advisor",
            desc: "We offer strategic insights and actionable solutions tailored to each business's unique needs.",
            link: "#!"
        },
        {
            img: "./../images/Heart.png",
            title: "Brand Personal",
            desc: "Top-tier marketing agency that blends human psychology with digital expertise to create powerful branding strategies.",
            link: "#!"
        },
        {
            img: "./../images/Activity.png",
            title: "Development",
            desc: "Web development involves creating and maintaining websites, ensuring they are functional, user-friendly, and visually appealing.",
            link: "#!"
        },
    ]
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Service</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>What Can We Bring for You</h2>
                </div>

                <div className="row">
                    {
                        props.type == "left" ? services.map((obj) => {
                            return <div className="col-md-6 col-lg-4 mb-4">
                                <div className="wrapper__card-service position-relative">
                                    <img src="./../images/mouse 2.png" className='cursor' alt="" />
                                    <div className="position-relative z-2 left">
                                        <img src={obj.img} className='mb-5' alt="" />
                                        <div className='ml-3'>
                                            <h4 className='semi-bold font__size--24 text__24-1024'>{obj.title}</h4>
                                            <p className='normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }) : services.map((obj) => {
                            return <div className="col-md-6 col-lg-4 mb-4">
                                <div className="wrapper__card-service position-relative">
                                    <img src="./../images/mouse 2.png" className='cursor' alt="" />
                                    <div className="position-relative z-2">
                                        <img src={obj.img} className='mb-5' alt="" />
                                        <h4 className='semi-bold font__size--24 text__24-1024'>{obj.title}</h4>
                                        <p className='normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                                    </div>
                                </div>
                            </div>
                        })

                    }

                </div>
            </div>
        </section>
    )
}

export default Service