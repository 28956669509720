import React from 'react'

const ContactForm = () => {
    return (
        <section>
            <div className="container">
                <div className="wrapper__contact-wrap">
                    <div className="row">
                        <div className="col-md-6 mb-5 my-md-auto">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Contact Us</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>Contact Our Team,
                                Feel free to talk about anything.</h2>

                            <ul className="list__contact-list">
                                {/* <li>
                                    <div className="d-flex align-items-center">
                                        <div className="icon">
                                            <img src="./../images/pin (1).png" alt="" />
                                        </div>
                                        <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>2715 Ash Dr. San Jose, South Dakota 83475</div>
                                    </div>
                                </li> */}
                                {/* <li>
                                    <div className="d-flex align-items-center">
                                        <div className="icon">
                                            <img src="./../images/call.png" alt="" />
                                        </div>
                                        <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>(252) 555-0126</div>
                                    </div>
                                </li> */}
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div className="icon">
                                            <img src="./../images/email.png" alt="" />
                                        </div>
                                        <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>info@jktfincapital.com</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="col-md-6 col-lg-5 offset-lg-1 my-auto">
                            <div className="form-group">
                                <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Name" />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Phone" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control wrapper__field-input textarea light font__size--14 text__14-1024 color__gray-1" cols="30" rows="6" placeholder='Your Quenstions'></textarea>
                            </div>
                            <button className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow text-capitalize">Send Message</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm