import React from 'react'

const Choose = () => {
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Solutions</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Why Choose Us</h2>
                </div>

                <div className="row wrapper__choose-line-wrap">
                    <div className="col-md-4">
                        <div className="wrapper__card-choose">
                            <div className="icon mb-5">
                                <img src="./../images/mission-accomplished.png" alt="" />
                            </div>
                            <h4 className='semi-bold font__size--24 text__24-1024'>Great Experience</h4>
                            <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Great Experience offers top-notch branding, strategic marketing, and digital solutions to elevate your business and attract more customers.</p>
                        </div>
                    </div>
                    <div className="col-md-4 position-relative line-dual">
                        <div className="wrapper__card-choose">
                            <div className="icon mb-5">
                                <img src="./../images/idea (1).png" alt="" />
                            </div>
                            <h4 className='semi-bold font__size--24 text__24-1024'>Best Solutions</h4>
                            <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Expert branding, strategic marketing, digital trading, design, and growth programming services.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="wrapper__card-choose">
                            <div className="icon mb-5">
                                <img src="./../images/target.png" alt="" />
                            </div>
                            <h4 className='semi-bold font__size--24 text__24-1024'>Efective Strategy</h4>
                            <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Unlock success with our Effective Strategy services – where expertise meets innovation to drive your business forward.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Choose