import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Choose from "../../component/Section/Choose";
import ContactUs from "../../component/Section/ContactUs";
import Service from "../../component/Section/Service";

const Index = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">

        <Navbar />
        <div className="position-relative mt-4">
          <img src="./../images/Frame 8 (2).png" className="images__head-wrap d-none d-lg-block" alt="" />
          <section className="position-relative z-2">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-5">
                    <h1 className="semi-bold font__size--50 text__50-1024 text__50-sm">Creative Digital Agency That Help You Go Ahead</h1>
                    <p className="normal font__size--18 text__18-1024 lh-2 color__gray-1">We are a team of branding experts, marketing strategists, digital traders, designers, and growth programmers. Leveraging the power of human psychology and our deep knowledge of digital landscapes, we help businesses attract and retain customers.</p>
                    <NavLink to="/contact" className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow ml-auto text-capitalize">Contact Us</NavLink>
                  </div>

                  <div className="wrapper__rating-user">
                    <div className="rating mb-3 d-flex align-items-center">
                      <img src="./../images/star 1.png" alt="" />
                      <img src="./../images/star 1.png" alt="" />
                      <img src="./../images/star 1.png" alt="" />
                      <img src="./../images/star 1.png" alt="" />
                      <img src="./../images/star 1.png" alt="" />
                    </div>
                    <p className="normal font__size--14 text__14-1024 color__gray-2 lh-2">JKT Fin Capital deep understanding of human psychology and digital landscapes helped us attract and retain more customers than ever before.</p>

                    <div className="user d-flex align-items-center">
                      <img src="./../images/Ellipse 1.png" alt="" />
                      <div className="ml-3">
                        <h5 className="bold font__size--18 text__18-1024 mb-0">Sulli Kiri</h5>
                        <p className="semi-bold font__size--14 text__14-1024 color__gray-1 mb-0">CEO Dream Team</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Service />

        <Choose />

        <ContactUs />

        <Footer />

      </div >
    </Fragment >
  );
};

export default Index;
