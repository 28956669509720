import React from 'react'

const Head = (props) => {
    return (<section className="position-relative z-2">
        <div className="container text-center">
            <h2 className='semi-bold font__size--60 text__60-1024 text__60-sm'>{props.title}</h2>
            <p className='mb-0 normal font__size--18 text__18-1024'>Home / {props.page}</p>
        </div>
    </section>
    )
}

export default Head