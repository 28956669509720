import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactForm from '../../component/Section/ContactForm'
import Head from '../../component/Section/Head'

const Contact = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Contact us"} page={"Contact us"} />
                </div>

                <ContactForm />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Contact