import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const Privacy = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <section className="position-relative z-2">
                        <div className="container text-center">
                            <h2 className='semi-bold font__size--60 text__60-1024 text__60-sm'>Privacy Policy</h2>
                            <p className='mb-0 normal font__size--18 text__18-1024'>Last Update May 18, 2024</p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                            <p>
                            This privacy policy will help you understand how JKT Fin Capital Limited uses and protects the data you provide to us when you visit and use jktfincapital.com.
                        </p>
                        <p>
                            We reserve the right to change this policy at any given time, of which you will be
                            promptly updated. If you want to make sure that you are up to date with the latest
                            changes, we advise you to frequently visit this page.
                        </p>
                        <h2 className="semi-bold mt-lg-5 font__size--32 text__32-1024 text__32-md text__32-sm">What User Data We Collect</h2>
                        <p>When you visit the website, we may collect the following data:</p>
                        <ul>
                            <li>Your IP address.</li>
                            <li>Your contact information and email address.</li>
                            <li>Other information such as interests and preferences.</li>
                            <li>Data profile regarding your online behavior on our website.</li>
                        </ul>
                        <h2 className="semi-bold mt-lg-5 font__size--32 text__32-1024 text__32-md text__32-sm">Why We Collect Your Data</h2>
                        <p>We are collecting your data for several reasons:</p>
                        <ul>
                            <li>To better understand your needs.</li>
                            <li>To improve our services and products.</li>
                            <li>To send you promotional emails containing the information we think you will find interesting.</li>
                            <li>To contact you to fill out surveys and participate in other types of market research.</li>
                            <li>To customize our website according to your online behavior and personal preferences.</li>
                        </ul>
                        <h2 className="semi-bold mt-lg-5 font__size--32 text__32-1024 text__32-md text__32-sm">Safeguarding and Securing the Data</h2>
                        <p>JKT Fin Capital Limited is committed to securing your data and keeping it confidential.</p>
                        <p>
                            JKT Fin Capital Limited has done all in its power to prevent data theft, unauthorized access,
                            and disclosure by implementing the latest technologies and software, which help us
                            safeguard all the information we collect online.
                        </p>
                        <h2 className="semi-bold mt-lg-5 font__size--32 text__32-1024 text__32-md text__32-sm">Our Cookie Policy</h2>
                        <p>
                            Once you agree to allow our website to use cookies, you also agree to use the data it
                            collects regarding your online behavior (analyze web traffic, web pages you visit and
                            spend the most time on).
                        </p>
                        <p>The data we collect by using cookies is used to customize our website to your needs.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div >
        </Fragment >
    )
}

export default Privacy