import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const PageNotFound = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar />

                <section className='position-relative mt-5'>
                    <img src="./../images/Group 35267.png" className='images__bg-not' alt="" />
                    <div className="container position-relative z-2">
                        <div className="text-center">
                            <h1 className='semi-bold font__size--180 text__163-1024 color__pink'>404</h1>
                            <h5 className='semi-bold font__size--32 text_-32-1024'>Page Not Found</h5>
                            <p className='normal font__size--18 text__18-1024 color__gray-1'>The page you are looking for might have been removed had its name <br className='d-none d-md-block' /> changed or is temporarily unavailable</p>
                            <NavLink to="/" className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow text-capitalize">Back to Home</NavLink>
                        </div>
                    </div>
                </section>

                <Footer />

            </div >
        </Fragment >
    )
}

export default PageNotFound